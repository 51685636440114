$(document).ready(function () {
    
    $(".column .h3").click(function () {
        $screensize = $(window).width();
        if ($screensize < 768) {
            $(this).next(".hide").fadeToggle('fast');
        }
    });
    
    $('.button-search').bind('click', function () {
        url = $('base').attr('href') + 'index.php?route=product/search';

        var filter_name = $('input[name=\'filter_name\']').attr('value');

        if (filter_name) {
            url += '&filter_name=' + encodeURIComponent(filter_name);
        }

        location = url;
    });

    $('#header input[name=\'filter_name\']').bind('keydown', function (e) {
        if (e.keyCode == 13) {
            url = $('base').attr('href') + 'index.php?route=product/search';

            var filter_name = $('input[name=\'filter_name\']').attr('value');

            if (filter_name) {
                url += '&filter_name=' + encodeURIComponent(filter_name);
            }

            location = url;
        }
    });

    $(function () {
        $('.box-product .name, .product-box-list .name, .compare-slider .name, .compare-slider .description').dotdotdot();
    });

    $(function () {
        $(".fancybox").fancybox({
            openEffect: 'fade',
            closeEffect: 'fade',
            title: '',
            padding: 50,
            helpers: {
                overlay: {
                    locked: false,
                    css: {
                        'background': 'rgba(0,0,0,0.7)'
                    }
                }
            },
            tpl: {
                closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>',
                next: '<a title="Вперед" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                prev: '<a title="Назад" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
            },
            beforeLoad: function () {
                $("body").addClass("popup-open");
            },
            beforeClose: function () {
                $("body").removeClass("popup-open");
            }
        });
    });

    $(function () {

        $('.product-image-slider').bxSlider({
            pagerCustom: '#product-image-pager',
            controls: false,
            mode: 'fade'
        });

        $('.home-slider').bxSlider({
            mode: 'fade',
            controls: false,
            auto: true
        });

    });

    $(function () {

            $screensize = $(window).width();
            if ($screensize > 768) {
                $('.compare-slider').bxSlider({
                    minSlides: 2,
                    maxSlides: 2,
                    slideWidth: 394,
                    slideMargin: 0,
                    pager: false,
                    infiniteLoop: false,
                    hideControlOnEnd: true,
                    nextSelector: '#slider-next',
                    prevSelector: '#slider-prev',
                    nextText: '',
                    prevText: ''
                });
            }
            if ($screensize < 768) {
                $('.compare-slider').bxSlider({
                    minSlides: 1,
                    maxSlides: 1,
                    slideWidth: 300,
                    slideMargin: 0,
                    pager: false,
                    infiniteLoop: false,
                    hideControlOnEnd: true,
                    nextSelector: '#slider-next',
                    prevSelector: '#slider-prev',
                    nextText: '',
                    prevText: ''
                });
            }

    });

$(function () {

    $('select').selectric();

    //$("input[type='radio'], input[type='checkbox']").ionCheckRadio();

});

$('.catalog-dropdown, .catalog-mobile').click(function () {
    $(this).toggleClass('less-corners');
    $('.box-content-dropdown').fadeToggle('fast');
});

$(".qtyBtn").click(function () {
    if ($(this).hasClass("plus")) {
        var qty = $("#qty").val();
        qty++;
        $("#qty").val(qty);
    } else {
        var qty = $("#qty").val();
        qty--;
        if (qty > 0) {
            $("#qty").val(qty);
        }
    }
});

$(function () {
    setAutoComplete("filter_name", "results", "getdata.php?q=");
});

$('#button-cart').bind('click', function () {
    $.ajax({
        url: 'index.php?route=checkout/cart/add',
        type: 'post',
        data: $('.product-page input[type=\'text\'], .product-page input[type=\'hidden\'], .product-page input[type=\'radio\']:checked, .product-page input[type=\'checkbox\']:checked, .product-page select, .product-page textarea'),
        dataType: 'json',
        success: function (json) {

            if (json['error']) {
                if (json['error']['option']) {
                    for (i in json['error']['option']) {
                        $('#option-' + i).after('<span class="error">' + json['error']['option'][i] + '</span>');
                    }
                }
            }

            if (json['success']) {
                window.location = "/checkout";
            }
        }
    });
});

});

function getURLVar(urlVarName) {
    var urlHalves = String(document.location).toLowerCase().split('?');
    var urlVarValue = '';

    if (urlHalves[1]) {
        var urlVars = urlHalves[1].split('&');

        for (var i = 0; i <= (urlVars.length); i++) {
            if (urlVars[i]) {
                var urlVarPair = urlVars[i].split('=');

                if (urlVarPair[0] && urlVarPair[0] == urlVarName.toLowerCase()) {
                    urlVarValue = urlVarPair[1];
                }
            }
        }
    }

    return urlVarValue;
}

function addToCart(product_id, quantity) {
    quantity = typeof (quantity) != 'undefined' ? quantity : 1;

    $.ajax({
        url: 'index.php?route=checkout/cart/add',
        type: 'post',
        data: 'product_id=' + product_id + '&quantity=' + quantity,
        dataType: 'json',
        success: function (json) {
            $('.success, .warning, .attention, .information, .error').remove();

            if (json['redirect']) {
                location = json['redirect'];
            }

            if (json['success']) {

                html = '';
                Image = $('input[onclick="addToCart(\'' + product_id + '\');"]').parents().find('.image a');
                Name = $('input[onclick="addToCart(\'' + product_id + '\');"]').parents().find('.name a');
                Desc = $('input[onclick="addToCart(\'' + product_id + '\');"]').parents().find('.name .description');
                Price = $('input[onclick="addToCart(\'' + product_id + '\');"]').parents().find('.price');
                html += '<div class="h1">' + json['success'] + '</div>';
                html += '<div class="cart-popup-product clearfix">' + '<div class="image">' + Image.html() + '</div>' + '<div class="name">' + '<span>' + Name.html() + '</span>' + '</div>' + '<div class="description">' + Desc.html() + '</div>' + '<div class="price">' + Price.html() + '</div>' + '</div>';
                html += '<div class="h3">У вас в корзине</div>' + '<div id="cart-box-list"></div>';
                html += '<div class="checkout"><a class="button" href="/checkout">Оформить заказ</a><a class="button" onclick="parent.jQuery.fancybox.close()">Продолжить покупки</a></div></div>';
                $('#cart-popup').html(html);
                $('#cart-box-list').load('index.php?route=module/cart .mini-cart-info > *');

                $('#cart-total').html(json['total']);

                $.fancybox.open({
                    href: '#cart-popup',
                    autoSize: true,
                    maxWidth: 600,
                    minWidth: 600,
                    minHeight: 0,
                    maxHeight: 600,
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    padding: 50,
                    helpers: {
                        overlay: {
                            locked: false,
                            css: {
                                'background': 'rgba(0,0,0,0.7)'
                            }
                        }
                    },
                    tpl: {
                        closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>'
                    },
                    beforeLoad: function () {
                        $("body").addClass("popup-open");
                    },
                    beforeClose: function () {
                        $("body").removeClass("popup-open");
                    }
                });
            }
        }
    });
}

function addToWishList(product_id) {
    $.ajax({
        url: 'index.php?route=account/wishlist/add',
        type: 'post',
        data: 'product_id=' + product_id,
        dataType: 'json',
        success: function (json) {

            if (json['success']) {
                $('#notification').html('<div>' + json['success'] + '</div>');

                $.fancybox.open({
                    href: '#notification',
                    maxWidth: 600,
                    minWidth: 600,
                    minHeight: 0,
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    padding: 50,
                    helpers: {
                        overlay: {
                            locked: false,
                            css: {
                                'background': 'rgba(0,0,0,0.7)'
                            }
                        }
                    },
                    tpl: {
                        closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>'
                    },
                    beforeLoad: function () {
                        $("body").addClass("popup-open");
                    },
                    beforeClose: function () {
                        $("body").removeClass("popup-open");
                    }
                });

                $('#wishlist-total').html(json['total']);

            }
        }
    });
}

function addToCompare(product_id) {
    $.ajax({
        url: 'index.php?route=product/compare/add',
        type: 'post',
        data: 'product_id=' + product_id,
        dataType: 'json',
        success: function (json) {

            if (json['success']) {
                $('#notification').html('<div>' + json['success'] + '</div>');

                $.fancybox.open({
                    href: '#notification',
                    maxWidth: 600,
                    minWidth: 600,
                    minHeight: 0,
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    padding: 50,
                    helpers: {
                        overlay: {
                            locked: false,
                            css: {
                                'background': 'rgba(0,0,0,0.7)'
                            }
                        }
                    },
                    tpl: {
                        closeBtn: '<a title="Закрыть" class="fancybox-item fancybox-close" href="javascript:;"></a>'
                    },
                    beforeLoad: function () {
                        $("body").addClass("popup-open");
                    },
                    beforeClose: function () {
                        $("body").removeClass("popup-open");
                    }
                });

                $('#compare-total').html(json['total']);

            }
        }
    });
}