// Ion.CheckRadio
// version 1.1.0 Build: 23
// https://github.com/IonDen/ion.CheckRadio
(function(d) {
    var l = 0;
    String.prototype.trim || (String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, "")
    });
    var f = {
        init: function() {
            return this.each(function() {
                var b = d(this),
                    a, g, c, f, h, k, m, e;
                if (!b.data("isActive")) {
                    b.data("isActive", !0);
                    this.plugin_count = l += 1;
                    c = '<div class="icr__hidden" id="icr-container__' + this.plugin_count + '"></div>';
                    k = b.prop("type");
                    f = b.prop("disabled");
                    h = b.prop("checked");
                    m = b.prop("name");
                    a = b.parent("label");
                    if (0 < a.length) a.after(c), c = d("#icr-container__" + this.plugin_count),
                        b.appendTo(c), a.appendTo(c), e = a.html(), a = e.replace(/<input["-=a-zA-Z\u0400-\u04FF\s\d\[\]\_]+>{1}/, ""), e = a.trim();
                    else if (a = b.prop("id"), a = d("label[for='" + a + "']"), a.after(c), c = d("#icr-container__" + this.plugin_count), b.appendTo(c), a.appendTo(c), 0 < a.length) e = a.html(), a = e.toString(), e = a.trim();
                    else throw Error("Label not found!");
                    a = f ? h ? '<span class="icr disabled checked" id="icr-' + this.plugin_count + '">' : '<span class="icr disabled" id="icr-' + this.plugin_count + '">' : h ? '<span class="icr enabled checked" id="icr-' +
                        this.plugin_count + '">' : '<span class="icr enabled" id="icr-' + this.plugin_count + '">';
                    a = a + ('<span class="icr__' + k + '"></span>') + ('<span class="icr__text">' + e + "</span>");
                    a += "</span>";
                    c.after(a);
                    g = d("#icr-" + this.plugin_count);
                    (function() {
                        g.on("click", function() {
                            f || (h && "radio" !== k ? b.removeProp("checked") : b.prop("checked", "checked"), b.trigger("change"), d("input[name='" + m + "']").trigger("stateChanged"))
                        });
                        g.on("mousedown", function(a) {
                            a.preventDefault();
                            return !1
                        });
                        b.on("stateChanged", function() {
                            (h = b.prop("checked")) ?
                            g.addClass("checked"): g.removeClass("checked")
                        })
                    })()
                }
            })
        }
    };
    d.fn.ionCheckRadio = function(b) {
        return f[b] ? f[b].apply(this, Array.prototype.slice.call(arguments, 1)) : "object" !== typeof b && b ? (d.error("Method " + b + " does not exist for jQuery.ionCheckRadio"), !1) : f.init.apply(this, arguments)
    }
})(jQuery);